import React from "react";
import "./cbFooter.css";
import { PhoneNum, PhoneNumFooter } from "../inputs/phoneNum";
import { AiOutlineInstagram, AiFillLinkedin } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa";
import { BsPinterest, BsFacebook } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { BiLogoPinterestAlt, BiLogoLinkedin } from "react-icons/bi";
import { RiTwitterXFill } from "react-icons/ri";
import { Link } from "react-scroll";
import { MdEmail } from "react-icons/md";
import { BiSolidPhoneCall } from "react-icons/bi";

export function CBFooter() {
  const IndustryServices = [
    { label: "Food Delivery", link: "#" },
    { label: "Healthcare", link: "#" },
    { label: "Pickup & Delivery", link: "#" },
    { label: "E-Commerce Delivery", link: "#" },
    { label: "Taxi and Transportation", link: "#" },
    { label: "Home Services", link: "#" },
    { label: "Fitness", link: "#" },
    { label: "Education", link: "#" },
    { label: "Real Estate", link: "#" },
  ];
  const FooterServices = [
    { label: "Premium App Development Studio", link: "#" },
    { label: "Mobile App Development Studio",  link: "#"},
    { label: "Enterprise App Development",  link: "#" },
    { label: "Blockchain Development",  link: "#" },
    { label: "On Demand Development", link: "#" },
    { label: "UI/UX Design",  link: "#" },
    { label: "Web Development",  link: "#" },
    { label: "Growth & Marketing", link: "#" },
  ];
  const MarketplaceLink = [
    { label: "Service", link: "#" },
    { label: "Freelancer", link: "#" },
    { label: "E-Commerce", link: "#" },
    { label: "Peer-to-Peer", link: "#" },
    { label: "Rental", link: "#" },
  ];
  const quickLink = [
    { label: "Portfolio", link: "#" },
    { label: "Why Choose us", link: "#" },
    { label: "How we work", link: "#" },
  ];

  return (
    <div className="relative mt-[5rem] lg:mt-0 lg:fixed bottom-0 z-0 right-0 left-0">
      <div className="container1">
        <div className="flex">
          <div className="hidden lg:block w-[25%] footer-widget-common">
            <h4>Industries</h4>
            {IndustryServices.map((item, index) => (
              <p key={index}>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  href={item.link}
                >
                  {item.label}
                </Link>
              </p>
            ))}
          </div>
          <div className="hidden lg:block w-[25%] footer-widget-common">
            <h4>Services</h4>
            {FooterServices.map((item, index) => (
              <p key={index}>
                <a href={item.link}>{item.label}</a>
              </p>
            ))}
          </div>
          <div className="hidden lg:block w-[16%] footer-widget-common">
            <h4>Marketplace</h4>
            {MarketplaceLink.map((item, index) => (
              <p key={index}>
                <a href={item.link}>{item.label}</a>
              </p>
            ))}
            <br />
            <h4>Quick Links</h4>
            {quickLink.map((item, index) => (
              <p key={index}>
                <a href={item.link}>{item.label}</a>
              </p>
            ))}
          </div>
          <div className="w-full lg:w-[33.33%] footer-widget-common">
            <div className="commenFoterSection forWhatsAppForm mb-[1.5rem]">
              <h4>Whatsapp Updates</h4>
              <div>
                <PhoneNumFooter />
              </div>
              <small>Whatsapp Updates</small>
            </div>
            <div className=" px-[15px] py-[25px]">
              <h5 className="mt-[1rem] text-[16px] font-SF-Pro-Regular text-[#4c525a] leading-[1.56]">
                <span>
                  SCO 54, 2nd Floor, SEC-82, Airport Road Mohali, Punjab, India.
                </span>
                {/* <br /> */}
                <span> Pin code: 160055.</span>
                <br />
                <span className="flex items-center gap-[8px] mt-[0.7rem]">
                  <MdEmail /> Info@easywebplans.com
                </span>
                <span className="flex items-center gap-[8px] mt-[0.7rem]">
                  {" "}
                  <BiSolidPhoneCall /> +91 92160-41313, +91 85913-83962
                </span>
              </h5>
            </div>
            <div className="commenFoterSection forWhatsAppForm">
              <div className="forSocialIconsOnly flex flex-col justify-center lg:justify-normal">
                <h4 className="text-center lg:text-left">Follow us on</h4>
                <ul className="flex gap-[5px]">
                  <li
                    style={{ background: "#000", transition: "0.3s" }}
                    className="cursor-pointer hover:scale-[1.07]  w-[30px] h-[30px] flex items-center justify-center rounded-full"
                  >
                    <RiTwitterXFill className="text-[22px] text-white" />
                  </li>
                  <li
                    style={{ background: "#475993", transition: "0.3s" }}
                    className="cursor-pointer hover:scale-[1.07] w-[30px] h-[30px] flex items-center justify-center rounded-full"
                  >
                    <FaFacebookF className="text-[22px] text-white" />
                  </li>
                  <li
                    style={{ background: "#e60023", transition: "0.3s" }}
                    className="cursor-pointer hover:scale-[1.07] w-[30px] h-[30px] flex items-center justify-center rounded-full"
                  >
                    <BiLogoPinterestAlt className="text-[22px] text-white" />
                  </li>
                  <li
                    style={{ background: "#0077b7", transition: "0.3s" }}
                    className="cursor-pointer hover:scale-[1.07] w-[30px] h-[30px] flex items-center justify-center rounded-full"
                  >
                    <BiLogoLinkedin className="text-[22px] text-white" />
                  </li>
                  <li
                    style={{
                      background:
                        "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
                      transition: "0.3s",
                    }}
                    className="cursor-pointer hover:scale-[1.07] w-[30px] h-[30px] flex items-center justify-center rounded-full"
                  >
                    <AiOutlineInstagram className="text-[22px] text-white" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="foote-copyrights">
        <div className="container1">
          <div className="flex justify-between">
            <p className="text-left float-left">
              © Copyright 2023 EasyWebPlans | All Rights Reserved |{" "}
            </p>
            <p className="text-right float-right">
              Privacy Policy | Return Policy | Terms & conditions
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
