import React from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
// import { REACT_APP_GOOGLE_MAPS_KEY } from "../constants/constants";

const REACT_APP_GOOGLE_MAPS_KEY = "AIzaSyC3mZg6P7r2Aze0dm4X1QTmHora9Zs3fGQ";

const GoogleMapComponent = ({ selectedLocation }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
  });
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);
  if (loadError) return "Error";
  if (!isLoaded) return "Maps";

  return (
    <div>
     <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d109827.74377350495!2d76.65403527402691!3d30.658833908502945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x390febe0cae7da7f%3A0x8f34d8a3997fe1a1!2sS..C..O..54%2C%20SEC..82%2C%20Punjab%20160055!3m2!1d30.6588599!2d76.73643659999999!5e0!3m2!1sen!2sin!4v1699336385677!5m2!1sen!2sin"  
         width="100%"
        height="400"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
      {/* <GoogleMap
        mapContainerStyle={{
          height: "400px",
        }}
        yesIWantToUseGoogleMapApiInternals
        center={selectedLocation}
        // zoom={13}
        onLoad={onMapLoad}
      >
        <MarkerF
          position={selectedLocation}
          icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
        />
      </GoogleMap> */}
    </div>
  );
};

export default GoogleMapComponent;
