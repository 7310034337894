import React from "react";
import { AiFillStar } from "react-icons/ai";
import { BsFillStarFill } from "react-icons/bs";
import "./reviewCard.css";
import { Framer } from "../../animations/framerSection";

export default function ReviewCard() {
  return (
    <div
      style={{ transition: "0.4s" }}
      className="bg-white cursor-pointer review-card-bg px-[28px] py-[30px] rounded-lg w-full sm:w-[47%] lg:w-[22%] hover:scale-[1.03]"
    >
      <Framer>
        <h4
          style={{ fontWeight: "400" }}
          className="text-[24px] text-[#1a1c1b] flex items-center gap-x-[0.7rem] mb-[1rem]"
        >
          <AiFillStar className="text-[#f5ae00]" /> 4.9/5
        </h4>

        <img src="/assets/goodfirm-logo.png"></img>
        <p className="font-SF-Pro-Regular text-[16px] text-[#000000]">
          Reviewed by Goodfirms with 4.9/5 ratings based upon client reviews
        </p>
      </Framer>
    </div>
  );
}

export function ReviewCard1() {
  return (
    <div
      style={{ transition: "0.4s" }}
      className="bg-white text-center lg:text-left cursor-pointer review-card-bg px-[28px] py-[30px] rounded-lg w-full  hover:scale-[1.03]"
    >
      <h4
        style={{ fontWeight: "400" }}
        className="font-SF-Pro-Semibold text-[24px] text-[#1a1c1b] flex items-center justify-center lg:justify-normal gap-x-[0.7rem] mb-[1rem]"
      >
        <AiFillStar className="text-[#f5ae00]" /> 4.9/5
      </h4>

      <img className="mx-auto lg:mx-0" src="/assets/goodfirm-logo.png"></img>
      <p className="font-SF-Pro-Regular text-[16px] text-[#000000]">
        Reviewed by Goodfirms with 4.9/5 ratings based upon client reviews
      </p>
    </div>
  );
}
//Client Testimonial Software Development Page

export function ReviewCard2() {
  return (
    <div style={{ transition: "0.4s" }} className="client-testimonials">
      <div className="item">
        <h4>
          <img src="/assets/client-logo-3.jpeg"></img>
          <b className="font-SF-Pro-Semibold">Donald Trapper</b>
          <span className="font-SF-Pro-Regular">Founder Scoop</span>
        </h4>
        <p className="font-SF-Pro-Regular">
          We hired Code Brew about six years ago to help rebuild every aspect of
          an emergency app we created. The staff was friendly, courteous, and
          very professional.
        </p>
        <h5 className="flex">
          {" "}
          <AiFillStar className="text-[30px] text-[#f19f27]" />
          <AiFillStar className="text-[30px] text-[#f19f27]" />
          <AiFillStar className="text-[30px] text-[#f19f27]" />
          <AiFillStar className="text-[30px] text-[#f19f27]" />
          <AiFillStar className="text-[30px] text-[#f19f27]" />
        </h5>
      </div>
    </div>
  );
}

export function ReviewCardPremium() {
  return (
    <div
      style={{ transition: "0.4s" }}
      className="bg-gradient-to-b from-[#ffcfc5] to-[#ffffff] text-center lg:text-center cursor-pointer px-[28px] py-[30px] w-full border-t-[6px] border-[#eb5a3d]"
    >
      <h4
        style={{ fontWeight: "400" }}
        className="font-SF-Pro-Semibold text-[35px] text-[#1a1c1b]  gap-x-[0.7rem] mb-[1rem]"
      >
        4.9/5
      </h4>

      <img className="mx-auto" src="/assets/goodfirm-logo.png"></img>
      <p className="font-SF-Pro-Regular text-[16px] text-[#000000]">
        Interviewed & Platinum certified ratings.
      </p>
    </div>
  );
}

export function MarqueeReviewCard({content, clientImg, clientName}) {
  return (
    <div
      style={{
        boxShadow: "rgb(0 0 0/30%) 0px 1px 10px",
        lineHeight: 1.5,
        borderRadius: "20px",
      }}
      className="p-[20px] m-[1em] text-[#000] text-[14px] bg-[#f5f5f5] h-[300px] w-[350px]"
    >
      <div className="h-[250px] flex flex-col justify-between">
        <p className="font-SF-Pro-Semibold ">
          {content}
        </p>
        <div className="flex  justify-between">
          <div className="flex gap-[1rem]">
            <img src={clientImg} className="w-[40px] h-[40px] rounded-full"></img>
            <div>
              <small className="font-SF-Pro-Semibold text-[16px]">{clientName}</small>
              <div className="flex gap-[8px]">
                <BsFillStarFill className="text-[22px] text-[#f5ae00]"/>
                <BsFillStarFill className="text-[22px] text-[#f5ae00]"/>
                <BsFillStarFill className="text-[22px] text-[#f5ae00]"/>
                <BsFillStarFill className="text-[22px] text-[#f5ae00]"/>
                <BsFillStarFill className="text-[22px] text-[#f5ae00]"/>
              </div>
            </div>
          </div>
          <img className="w-[30px] h-[30px] my-auto" src="/assets/google.webp"></img>
        </div>
      </div>
    </div>
  );
}
