import React from "react";
import "./TransitionCard.css";
import "./TransitionCard1.css";
import { Framer } from "../../animations/framerSection";

export function TransitionCard({ imgSrc, label, content }) {
  return (
    <li class="cursor-pointer">
      <img className="m-auto h-[3rem]" src={imgSrc}></img>
      <h3 className="text-black mt-[1.2rem] leading-[1.3] font-SF-Pro-Regular text-[18px]">
        {label}
      </h3>
      <p className="font-SF-Pro-Regular">{content}</p>
    </li>
  );
}
export function TransitionCard1({
  imgSrc,
  label,
  content,
  index,
  backgroundColor,
}) {
  return (
    <li
      style={{
        backgroundColor: backgroundColor,
      }}
      class="cursor-pointer"
    >
      <img className="m-auto " src={imgSrc}></img>
      <h3 className="text-black mt-[1.2rem] leading-[1.3] font-SF-Pro-Regular text-[18px]">
        {label}
      </h3>
      <p className="font-SF-Pro-Regular">{content}</p>
    </li>
  );
}
