import React, {useState} from "react";
import "./button3.css";
import { Link } from "react-scroll";
import ModalFormat from "../../modal/modalFormat";
import { ContactCard } from "../../sections/home-section/contactForm1/contact-card";


export function Button3({text}) {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div className="flex">
      {/* <a class="whitespace-nowrap animated-button"> */}

      <div className="scroll-to-down-text flex">
        <a
          className="text-[#fff] whitespace-nowrap animated-button cursor-pointer"
          // activeClass="active"
          // to="/contactUs"
         onClick={()=>setIsOpen(true)}
          // spy={true}
          // smooth={true}
          // offset={-70} // Adjust this offset as needed
          // duration={500} // Adjust the scroll duration as needed
        >
  
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          {text}
        </a>
      </div>
      {/* </a> */}
      <ModalFormat isOpen={isOpen} onClose={closeModal}>
        <div className="mt-[5rem]">
          <ContactCard />
        </div>
      </ModalFormat>
    </div>
  );
}
