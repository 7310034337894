import React, { useState, useEffect } from "react";
import "./Custom.css";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";

export default function CustomCarousel({ items, indicators }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoplayPaused, setAutoplayPaused] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 990);

  useEffect(() => {
    // Add a window resize event listener to update isMobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 990);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const prevItem = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const nextItem = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };
  const goToSlide = (index) => {
    setCurrentIndex(index);
  };
  useEffect(() => {
    let interval;

    if (!autoplayPaused) {
      interval = setInterval(() => {
        nextItem();
      }, 2000); // Adjust the autoplay duration (in milliseconds) as needed
    }

    return () => {
      clearInterval(interval);
    };
  }, [autoplayPaused]);

  const handleMouseEnter = () => {
    setAutoplayPaused(true);
  };

  const handleMouseLeave = () => {
    setAutoplayPaused(false);
  };

  return (
    <div
      className="flex relative flex-col lg:flex-row items-center justify-center lg:justify-between"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="carousel-container flex items-center justify-center w-[50%] lg:w-[35%]">
        {isMobile && (
          <button
            className="absolute left-0 top-[40%] translate-y-[-50%]"
            onClick={prevItem}
          >
            <AiOutlineLeft className="text-3xl" />
          </button>
        )}

        <div className="carousel">
          <div className="carousel-inner ">
            {items.map((item, index) => (
              <div
                key={index}
                className={`carousel-item ${
                  index === currentIndex ? "active" : ""
                }`}
              >
                <img
                  className="mx-auto w-[200px] lg:w-full"
                  src={item.slideImg}
                ></img>
              </div>
            ))}
          </div>
        </div>
        {isMobile && (
          <button
            className="absolute right-0 top-[40%] translate-y-[-50%]"
            onClick={nextItem}
          >
            <AiOutlineRight className="text-3xl" />
          </button>
        )}
      </div>

      <div className="hidden indicators w-[65%]  lg:grid grid-cols-3">
        {items.map((indicator, index) => (
          <div
            key={index}
            onMouseEnter={() => goToSlide(index)}
            // onClick={() => goToSlide(index)}
            className={`h-[12rem] w-full border-[1px] p-[20px] border-gray-100 overflow-hidden  rounded-2xl flex flex-col items-center justify-center  indicator ${
              index === currentIndex ? "active " : ""
            }`}
          >
            <div
              style={{ transition: "0.5s" }}
              className={`flex gap-y-[0.5rem] items-center justify-center flex-col w-full h-full rounded-md border-white ${
                index === currentIndex
                  ? " bg-[#1ba37e] text-white rounded-md"
                  : "text-black"
              }`}
            >
              <div className="relative">
                <img
                  className={`h-[3rem] relative z-10 ${
                    index === currentIndex ? "active-img" : ""
                  }`}
                  src={indicator.indicatorImg}
                  alt={`Indicator ${index}`}
                />
                <img
                  className={`transform -scale-x-100  absolute left-[10px] top-[-8px] -rotate-70 z-0 opacity-[0.7] h-[1.7rem] ${
                    index === currentIndex ? "active-img" : ""
                  }`}
                  src="/assets/blob-shape.png"
                />
              </div>
              <span
                style={{ lineHeight: "1.3", fontWeight:"500" }}
                className="font-SF-Pro-Regular px-[10px] text-center text-[16px]"
              >
                {indicator.label}
              </span>
            </div>
          </div>
        ))}
      </div>
      {isMobile && (
        <div>
          <p className="text-[18px] text-black font-SF-Pro-Bold mt-[20px]">
            {items[currentIndex].label}
          </p>
        </div>
      )}
    </div>
  );
}
