import React from "react";

export function RadioBtn({id,text, name, fontSize}) {
  const labelStyle = {
    fontSize: fontSize || "14px", // Use the provided fontSize or default to "14px"
  };
  return (
    // <div className="radiobtns flex flex-wrap text-inherit">
      <div style={{width:"100%", display:"flex", gap:"5px"}} className="optionbox">
        <input
          type="radio"
          id={id}
          name={name}
          value={text}
          required
        />
        <label style={labelStyle} className="text-black font-SF-Pro-Regular whitespace-nowrap leading-[1.2]" htmlFor={id}>
          {text}
        </label>
      </div>
    // </div>
  );
}
