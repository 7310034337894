import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./slider.css";
import { Framer } from "../../../animations/framerSection";
import { BiLogoPlayStore } from "react-icons/bi";
import ModalFormat from "../../../modal/modalFormat";
import { ContactCard } from "../contactForm1/contact-card";
import { AiFillAndroid, AiFillApple } from "react-icons/ai";
const SliderData = [
  {
    imgSrc: "/assets/food-mobile-group.png",
    img1: "/assets/app-dev-slider/1-food.png",
    img2: "/assets/app-dev-slider/2-food.png",
    backgroundClass: "bg-black",
    ContentAlign: "right",
    content:
      "Food delivery apps have revolutionized meal ordering, allowing individuals to effortlessly order food using a few simple taps on their mobile devices. Our food delivery apps have been personalized to align with your individual preferences",
  },
  {
    imgSrc: "/assets/music-mobile-group.png",
    img1: "/assets/app-dev-slider/2-music.png",
    img2: "/assets/app-dev-slider/1-music.png",
    backgroundClass: "red-gradient",
    ContentAlign: "left",
    content:
      "Amidst a generation characterized by the prominence of influencers, our focus is on creating tailored mobile applications, which keeps us in step with the constant technological changes.",
  },
  {
    imgSrc: "/assets/grocery-mobile-group.png",
    img1: "/assets/app-dev-slider/1-grocery.png",
    img2: "/assets/app-dev-slider/2-grocery.png",
    backgroundClass: "slider-4",
    ContentAlign: "right",
    content:
      "Take a virtual stroll through our online grocery store. Explore a diverse selection, from fresh flowers to essential home care products, electronics, and more. Our user-friendly app is neatly organized into distinct sections, ensuring a smooth grocery shopping experience.",
  },
  {
    imgSrc: "/assets/education-mobile-group.png",
    img1: "/assets/app-dev-slider/1-education.png",
    img2: "/assets/app-dev-slider/2-education.png",
    backgroundClass: "bg-[#002042]",
    ContentAlign: "left",
    content:
      "Introducing our all-inclusive education app, where you can conveniently keep track of your grades, attendance, subject videos, and more. The brilliance of our in-house design studio ensures an ever-improving user experience.",
  },
];
export default function SwipeSlider() {
  // const [visibleSlides, setVisibleSlides] = useState({});
  const [isPaused, setIsPaused] = useState(false);
  const [visibleImg1, setVisibleImg1] = useState(
    Array(SliderData.length).fill({ img1Visible: false })
  );

  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  var settings = {
    arrows: true,
    autoplay: !isPaused,
    speed: 400,
    autoplaySpeed: 3000,
    cssEase: "linear",
    // dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Event handler to pause the slider
  const handleSliderPause = () => {
    setIsPaused(true);
  };

  // Event handler to unpause the slider
  const handleSliderUnpause = () => {
    setIsPaused(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const img1Elements = document.querySelectorAll(".img1");
      img1Elements.forEach((element, index) => {
        const elementRect = element.getBoundingClientRect();
        if (elementRect.top < window.innerHeight) {
          setVisibleImg1((prevVisible) => {
            const updatedVisible = [...prevVisible];
            updatedVisible[index] = { img1Visible: true };
            return updatedVisible;
          });
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="mt-[2rem] overflow-y-visible overflow-x-hidden h-full  cursor-grab">
        <Slider {...settings}>
          {SliderData.map((item, index) => (
            <div
              onTouchStart={handleSliderPause}
              onTouchEnd={handleSliderUnpause}
              key={index}
              className={`relative h-[50rem] sm:h-[46rem] py-[3rem] lg:py-[6rem] ${item.backgroundClass}`}
            >
              <div className="container h-full">
                <div className="h-full lg:px-[4rem] flex flex-col lg:flex-row items-center gap-x-[2rem] gap-y-[2rem]">
                  <div
                    className={`w-full order-2  ${
                      item.ContentAlign === "right"
                        ? "lg:order-2"
                        : "lg:order-1"
                    }  lg:w-[50%] flex justify-center new-heading-style-1`}
                  >
                    <div className="lg:w-[100%] flex flex-col items-center lg:items-start crausal-text">
                      {/* <Framer entrance={"bottom"}>
                        <h5 className="flex items-center gap-x-3">
                          <img className="" src="/assets/yumm_logo.png" />
                        </h5>
                      </Framer> */}
                      <p
                        style={{ fontWeight: "500" }}
                        className=" text-[16px] lg:text-[20px] lg:my-[40px] text-center lg:text-justify"
                      >
                        <Framer entrance={"bottom"}>{item.content}</Framer>
                      </p>
                      <Framer entrance={"bottom"}>
                        <div className="text-white mb-[1rem] lg:mb-[2rem] flex items-center gap-x-[1rem]">
                          <span className="  text-[16px] lg:text-[20px]">
                            Available for
                          </span>
                          <div className="mt-[2px] flex gap-[4px]">
                            <AiFillApple
                              style={{ transition: "0.3s" }}
                              className="text-[2rem] hover:scale-[1.07]"
                            />
                            <AiFillAndroid
                              style={{ transition: "0.3s" }}
                              className="text-[2rem] hover:scale-[1.07]"
                            />
                          </div>
                        </div>
                      </Framer>
                      <div className="w-full">
                        <Framer entrance={"bottom"}>
                          <div>
                            <ul className=" text-white justify-center lg:justify-normal">
                              <li className="text-center lg:text-left">
                                <big className="text-center lg:text-left">
                                  10K+
                                </big>
                                <small className="text-center lg:text-left">
                                  Downloads
                                </small>
                              </li>
                              <li className="text-center lg:text-left">
                                <big className="text-center lg:text-left">
                                  5/5
                                </big>
                                <small className="text-center lg:text-left">
                                  Rating
                                </small>
                              </li>
                            </ul>
                          </div>
                        </Framer>
                      </div>
                      <div className="absolute lg:relative bottom-[5%] mt-[2rem] lg:mt-[3rem]">
                        <Framer entrance={"bottom"}>
                          <button
                            onClick={() => setIsOpen(true)}
                            className="ripple-effect-button overflow-hidden text-[16px] lg:text-[18px] bg-transparent py-[13px] pl-[47px] pr-[20px] text-white border-[1px] border-white"
                          >
                            Reach Our Team
                          </button>
                        </Framer>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`w-full order-1 ${
                      item.ContentAlign === "right"
                        ? "lg:order-1"
                        : "lg:order-2"
                    } items-center  lg:w-[50%] flex justify-center`}
                  >
                    {/* <img className="w-[200px] lg:w-full" src={item.imgSrc} /> */}
                    <div className="m-auto  flex w-[300px] lg:w-[750px]">
                      <div>
                        <Framer entrance={"top"}>
                          <img src={item.img2}></img>
                        </Framer>
                      </div>
                      <div className="ml-[-40px] lg:ml-[-100px]">
                        <Framer entrance={"right"}>
                          <img className="-rotate-12" src={item.img1}></img>
                        </Framer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <ModalFormat isOpen={isOpen} onClose={closeModal}>
        <ContactCard />
      </ModalFormat>
    </>
  );
}
