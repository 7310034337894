import React from "react";
import "./button7.css";

export default function Button7({text}) {
  return (
    <div className="relative">
      <button className="button7 relative">
        <div class="left"></div>
        <span className="z-10 relative text-white font-SF-Pro-Semibold">
          {text}
        </span>
        <div class="right"></div>
      </button>
    </div>
  );
}
