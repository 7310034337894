import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import InputField from "../../../inputs/input";
import TextArea from "../../../inputs/textArea";
import { Button5 } from "../../../buttons/button5/button5";
import { Framer } from "../../../animations/framerSection";
import { PhoneNum } from "../../../inputs/phoneNum";
import { RadioBtn } from "../../../inputs/radiobtn";
import { Button6 } from "../../../buttons/button6/button6";
import { Message } from "@mui/icons-material";
import { BsPatchCheckFill } from "react-icons/bs";

const meetingService = [
  { label: "Black Tea", id: "option1" },
  { label: "Green Tea", id: "option2" },
  { label: "Coffee", id: "option5" },
  { label: "Virtual Tea", id: "option6" },
];
export function ContactCard() {
  const form = useRef();
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    if(!isValid){
      alert("Please enter valid mobile number!");
      return;
    }
    setIsSending(true); // Show loading message

    const formData = new FormData(form.current);
    const websiteName = "EasyWebPlans Teams";

    const data = {
      from_name: formData.get("from_name"),
      from_email: formData.get("from_email"),
      from_phnNum: formData.get("from_phnNum"),
      snack: formData.get("snack"),
      message: formData.get("message"),
      from_website: websiteName, // This is your predefined value
    };

    emailjs
    .send("service_iuxkzyp", "template_bj7guia", data, "-WyqEPrHNBiPDelfN")
      .then(
        (result) => {
          console.log(result.text);
          setIsSending(false);
          setIsSent(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <form
      ref={form}
      onSubmit={sendEmail}
      // style={divStyle}
      className="mt-[2rem] w-[100%] mr-7 lg:mr-0  lg:mt-[0] lg:ml-auto lg:flex flex-col justify-around "
    >
      {/* <div className="absolute"> */}

      <div
        style={{
          // boxShadow:
          //   "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
          border: "1px solid #e5e1ee",
        }}
        className="h-[39rem] xs:h-[37rem] sm:h-[36rem]  contact-container bg-[#e5e1ee] mx-[1rem] lg:mx-[auto] my-[1rem]  w-full lg:w-[28rem] xl:w-[520px]"
      >
        <div className="bg-white h-full rounded-2xl xs:translate-x-[8px] xs:translate-y-[8px]">
          <div
            style={{ margin: "0px", paddingBottom: "0px" }}
            className="relative  input-container row mx-0  w-full pt-4 "
          >
            <div className="hidden xs:flex absolute top-[12px] xl:top-[40px] left-[20px] xs:left-[40px]  gap-x-1">
              <div className="rounded-full bg-[#Fc1d04] dot-shape" />
              <div className="rounded-full bg-[#fc9c04] dot-shape" />
              <div className="rounded-full bg-[#14b808] dot-shape" />
            </div>
            <div className="relative leading-7">
              <h3
                className=" text-center text-[22px] font-Gilroy-Bold"
                style={{ color: "black" }}
              >
                Let's Build Your{" "}
                <span className="font-Gilroy-Bold">Dream App!</span>
                {/* <br className="hidden lg:block"/>
              Dream App */}
              </h3>
              <div
                style={{ opacity: "0.6" }}
                className="hidden lg:block absolute top-[40px] xs:top-[28px] md:top-[50px] right-[-18px]"
              >
                <Framer>
                  <img
                    className="w-[50px]"
                    src="/assets/contact-arrow-vector.svg"
                  />
                </Framer>
              </div>
            </div>
          </div>

          {isSent ? (
            <Framer>
              <div className="  mt-[4rem] input-container w-full h-full input-box-container flex gap-x-[0.6rem]">
                <BsPatchCheckFill className="text-[#4adc60] text-[30px] " />
                <h5 className="text-[#292828] font-SF-Pro-Bold text-[20px]">
                  Thank you! Your submission has been received!
                </h5>
              </div>
            </Framer>
          ) : (
            <div
              style={{ paddingTop: "0px" }}
              className="input-container w-full input-box-container"
            >
              <div className="flex flex-col justify-around gap-y-[1rem] xl:gap-y-[0.8rem]">
                <InputField
                  name="from_name"
                  border={"border-b"}
                  placeholder="First Name"
                  id="name"
                />
                <InputField
                  name={"from_email"}
                  border={"border-b"}
                  placeholder="Email"
                  type={"email"}
                  id="email"
                />
               <PhoneNum name="from_phnNum" isValid={isValid} setIsValid={setIsValid} required />
                <TextArea
                  name={"message"}
                  border={"border-b"}
                  placeholder={"What's your Project about?"}
                />
                <div className="text-left chai">
                  <p
                    style={{
                      fontSize: "14px",
                      textAlign: "left",
                      paddingBottom: "10px",
                      paddingTop: "0",
                      color: "black",
                    }}
                    className=" text-black font-SF-Pro-Semibold leading-[1.2]"
                  >
                    What would you like to have during our business meeting?
                  </p>

                  <ul className="grid grid-cols-2 xs:grid-cols-4 ">
                    {meetingService.map((item, index) => (
                      <div key={index} className="w-full">
                        <RadioBtn
                          name={"snack"}
                          id={item.id}
                          text={item.label}
                        />
                      </div>
                    ))}
                  </ul>
                </div>
                <div className="w-full xl:mt-[0.5rem] h-full flex flex-col justify-center  gap-y-[0.7rem] ">
                  <Framer>
                    <button
                      type="submit"
                      style={{ letterSpacing: "1px" }}
                      className="text-[18px] mt-[10px] text-white w-full bg-[#140844] font-SF-Pro-Bold py-[16px] rounded-md"
                    >
                      {isSending ? "Please Wait..." : "Yes, call me!"}
                    </button>
                  </Framer>

                  <Framer delay={"1s"}>
                    <p
                      style={{
                        lineHeight: "1.3",
                        fontSize: "14px",
                        paddingBottom: "15px",
                      }}
                      className="font-GalanoRegular    text-center"
                    >
                      We call back in 10-30 minutes, gauranteed!
                    </p>
                  </Framer>
                </div>
              </div>
            </div>
          )}

          {/* */}
        </div>
      </div>
      {/* </div> */}
    </form>
  );
}
