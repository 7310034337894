import React, { useState, useEffect } from "react";
import ModalFormat from "../../modal/modalFormat";
import { ContactCard } from "../../sections/home-section/contactForm1/contact-card";

export function Dropdown({ label, menu, grid, cols }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navItem2 = [
    { label: "About Us" },
    { label: "Partnership" },
    { label: "How We Work" },
    { label: "Why Choose us" },
    { label: "Why Choose us" },
  ];

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  useEffect(() => {
    // Function to update the window width
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      className="font-GalanoSemiBold font-semibold relative inline-block my-0 mx-[5px]"
      onClick={() => setIsHovered(!isHovered)}
      onMouseEnter={windowWidth >= 992 ? handleMouseEnter : null}
      onMouseLeave={windowWidth >= 992 ? handleMouseLeave : null}
    >
      <div
        style={{ display: "flex", flexDirection: "row" }}
        className="relative menuItem font-SF-Pro-Semibold whitespace-nowrap justify-between flex"
      >
        {label}
        <span className="ml-2 xl:hidden w-fit flex items-center">
          {isHovered ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 transition-transform transform inline-block"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3.293l-6.646 6.647a1 1 0 101.414 1.414L10 5.414l5.232 5.232a1 1 0 101.414-1.414L10 3.293z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 transition-transform transform rotate-180 inline-block"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3.293l-6.646 6.647a1 1 0 101.414 1.414L10 5.414l5.232 5.232a1 1 0 101.414-1.414L10 3.293z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </span>
      </div>

      {isHovered && (
        <>
          <ul
          
            className={`menuList mega-sub-menu ${grid===true?`grid-cols-${cols}`:"" }`} 
          >
            {menu.map((nav, index) => (
              <div key={index} className={`${grid===true?"col-span-1":""}`}>

                <div className="w-full ">
                  {nav.items.map((subItem, index) => (
                    <div onClick={()=>setIsOpen(true)}>
                      <li key={index} className=" flex flex-row flip-container">
                        <a className="w-full" >
                          {/* <div className="w-[40px] mr-3"> */}
                          <img
                            src={subItem.imgsrc}
                            className="flip-this h-[35px] mr-2 w-fit"
                          />
                          {/* </div> */}
                          <div className="w-full text-sm whitespace-normal xl:whitespace-nowrap flex items-center font-SF-Pro-Semibold">
                            {subItem.itemName}
                          </div>
                        </a>
                      </li>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </ul>
        </>
      )}
       <ModalFormat isOpen={isOpen} onClose={closeModal}>
        <div className="mt-[5rem]">
          <ContactCard />
        </div>
      </ModalFormat>
    </div>
  );
}
