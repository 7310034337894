import React, { useState, useEffect } from "react";
import { Dropdown } from "./inputs/nav/dropdown";
import { Squash as Hamburger } from "hamburger-react";
import { HiArrowDown } from "react-icons/hi";
import { Button3 } from "./buttons/button3/button3";
import { Button4 } from "./buttons/button4/button4";
import { Link } from "react-scroll";
import { NavItem } from "./inputs/nav/navItem";
import { DropdownLarge } from "./inputs/nav/dropdownLarge";
import { MdEmail } from "react-icons/md";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import { Framer } from "./animations/framerSection";
import ModalFormat from "./modal/modalFormat";
import { ContactCard } from "./sections/home-section/contactForm1/contact-card";

function Navbar() {
  // const [visible, setVisible] = useState(true);
  // const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  const [activeTab, setActiveTab] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const [showHamburger, setShowHamburger] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const navItem1 = [
    { heading: ["Heading1"], label: "About Us" },
    { label: "Partnership" },
    { label: "How We Work" },
    { label: "Why Choose us" },
  ];
  const ServicesData = [
    {
      name: "",
      items: [
        {
          href: "#",
          imgsrc: "/assets/app-dev-easy/nav/premium.png",
          itemName: "Premium Custom Solutions",
        },
        {
          href: "#",
          imgsrc: "/assets/app-dev-easy/nav/app-develop.png",
          itemName: "Mobile App Solutions",
        },
        {
          href: "#",
          imgsrc: "/assets/app-dev-easy/nav/enter-2.png",
          itemName: "Enterprise Software Development",
        },
        {
          href: "#",
          imgsrc: "/assets/app-dev-easy/nav/webdevelopment.png",
          itemName: "Web Development",
        },
      ],
    },
    {
      name: "",
      items: [
        {
          href: "#",
          imgsrc: "/assets/app-dev-easy/nav/block-chain.png",
          itemName: "Blockchain Development",
        },
        {
          href: "#",
          imgsrc: "/assets/app-dev-easy/nav/fintech-develpment.png",
          itemName: "Fintech Development",
        },
        {
          href: "#",
          imgsrc: "/assets/app-dev-easy/nav/ux-ui.png",
          itemName: "UI/UX Design - Allurive",
        },
        {
          href: "#",
          imgsrc: "/assets/app-dev-easy/nav/growth.png",
          itemName: "Growth & Marketing",
        },
      ],
    },
  ];
  const IndustryData = [
    {
      name: "On Demand",
      items: [
        {
          href: "#",
          imgsrc: "/assets/HippoImg.svg",
          itemName: "Food Delivery",
        },
        {
          href: "#",
          imgsrc: "/assets/HippoImg.svg",
          itemName: "Grocery Delivery",
        },
        {
          href: "#",
          imgsrc: "/assets/HippoImg.svg",
          itemName: "E-commerce Delivery",
        },
        {
          href: "#",
          imgsrc: "/assets/HippoImg.svg",
          itemName: "Pharmacy Delivery",
        },
        {
          href: "#",
          imgsrc: "/assets/HippoImg.svg",
          itemName: "Pickup & Delivery",
        },
      ],
    },
    {
      name: "Services",
      items: [
        {
          href: "#",
          imgsrc: "/assets/HippoImg.svg",
          itemName: "Taxi & Transportation",
        },
        {
          href: "#",
          imgsrc: "/assets/HippoImg.svg",
          itemName: "Beauty & Salon",
        },
        {
          href: "#",
          imgsrc: "/assets/HippoImg.svg",
          itemName: "Home Services",
        },
        {
          href: "#",
          imgsrc: "/assets/HippoImg.svg",
          itemName: "Laundry Services",
        },
        {
          href: "#",
          imgsrc: "/assets/HippoImg.svg",
          itemName: "Conciege Services",
        },
      ],
    },
    {
      name: "Others",
      items: [
        { imgsrc: "/assets/HippoImg.svg", itemName: "Education" },
        { imgsrc: "/assets/HippoImg.svg", itemName: "Fitness" },
        { imgsrc: "/assets/HippoImg.svg", itemName: "Healthcare" },
        { imgsrc: "/assets/HippoImg.svg", itemName: "Logistics" },
        { imgsrc: "/assets/HippoImg.svg", itemName: "Real Estate" },
      ],
    },
    {
      name: "Marketplace",
      items: [
        { imgsrc: "/assets/HippoImg.svg", itemName: "Service" },
        { imgsrc: "/assets/HippoImg.svg", itemName: "Rental" },
        { imgsrc: "/assets/HippoImg.svg", itemName: "Freelancer" },
        { imgsrc: "/assets/HippoImg.svg", itemName: "Ecommerce" },
        { imgsrc: "/assets/HippoImg.svg", itemName: "Peer-to-Peer" },
      ],
    },
    {
      name: "Solutions",
      items: [
        { imgsrc: "/assets/HippoImg.svg", itemName: "On Demand App" },
        { imgsrc: "/assets/HippoImg.svg", itemName: "Consultation App" },
        { imgsrc: "/assets/HippoImg.svg", itemName: "Social Media App" },
        { imgsrc: "/assets/HippoImg.svg", itemName: "Dating App" },
      ],
    },
  ];
  const CompanyData = [
    {
      name: "",
      items: [
        {
          // imgsrc: "/assets/HippoImg.svg",
          itemName: "About Us",
          href: "#",
        },
        {  itemName: "Partnership", href: "#" },
        {  itemName: "How We Work", href: "#" },
        {
          // imgsrc: "/assets/HippoImg.svg",
          itemName: "Why Choose Us",
          href: "#",
        },
      ],
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth <= 991) {
        setShowHamburger(true);
      } else {
        setShowHamburger(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Initial check for screen size
    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleVerticalScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > prevScrollPos) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleVerticalScroll);

    return () => window.removeEventListener("scroll", handleVerticalScroll);
  });
  return (
    <nav
      // className={` fixed z-50 top-0 w-full  transition-all duration-300 ease-in-out  ${
      //   scrolling ? "shadow-xl bg-white" : "bg-transparent"
      // }`}
      style={{ zIndex: "999999999" }}
      className={` fixed z-50 w-full py-[5px] transition-all duration-300 ease-in-out   ${
        scrolling ? "shadow-xl bg-white" : "bg-white"
      }`}
    >
      <div class="container relative">
        <ul className="flex flex-col xl:flex-row relative justify-between xl:items-center">
          <li className="w-[180px] flex items-center">
            <a  href="/">
              <img src="/assets/easywebplans-newlogo.png" alt="Logo" />
            </a>
          </li>
          <li
            className={`navbar-content-2 ${menuOpen ? "" : "hidden"} xl:block `}
          >
            {/* {showHamburger ? ( */}

            {/* ) : ( */}

            <ul
              // style={{ justifyContent: "right" }}
              className="flex justify-start flex-col xl:flex-row gap-x-[0.7rem] xl:pl-[2rem]"
            >
              <Dropdown
                label={"Services"}
                menu={ServicesData}
                grid={true}
                cols={2}
              />
              {/* <DropdownLarge label={"Industry"} menu={IndustryData} /> */}
              <Dropdown label={"Company"} menu={CompanyData} />
              <NavItem label={"Live Demos"} />
              <NavItem label={"Portfolio"} />
            </ul>
            {/* )} */}
          </li>
          <div className={`my-[1.5rem] xl:hidden ${menuOpen ? "" : "hidden"}`}>
            <div className="relative pb-[30px] flex gap-x-[1rem]">
              <li
                onClick={() => setActiveTab(0)}
                className="cursor-pointer w-[60px] h-[60px] flag"
              >
                <img
                  className="rounded-full border-[2px] border-[#70aa26] p-[5px]"
                  src="/assets/india-flag-round.png"
                ></img>
                {activeTab === 0 && (
                  <HiArrowDown className="text-[20px] flag-after mx-auto" />
                )}
              </li>
              <li
                onClick={() => setActiveTab(1)}
                className="cursor-pointer w-[60px] h-[60px] flag"
              >
                <img
                  className="rounded-full border-[2px] border-[#70aa26] p-[5px]"
                  src="/assets/india-flag-round.png"
                ></img>
                {activeTab === 1 && (
                  <HiArrowDown className="text-[20px] flag-after mx-auto" />
                )}
              </li>
              <li
                onClick={() => setActiveTab(2)}
                className="cursor-pointer w-[60px] h-[60px] flag"
              >
                <img
                  className="rounded-full border-[2px] border-[#70aa26] p-[5px]"
                  src="/assets/india-flag-round.png"
                ></img>
                {activeTab === 2 && (
                  <HiArrowDown className="text-[20px] flag-after mx-auto" />
                )}
              </li>
            </div>
            <div className="ml-[10px]">
              {activeTab === 0 && (
                <Framer>
                  <h4 className="text-[22px] font-SF-Pro-Semibold text-[#131d2b] leading-[1.36]">
                    India - Mohali
                  </h4>
                  <h5 className="text-[16px] text-[#4c525a] leading-[1.56] mt-[0.3rem]">
                    <span className="flex items-center gap-[8px]">
                      SCO 54, SEC-82, Airport Road Mohali, Punjab, India.
                    </span>
                    {/* <br /> */}
                    <span> Pin code: 160055.</span>
                    <br />
                    <span className="flex items-center gap-[8px] mt-[0.7rem]">
                      <MdEmail /> Info@easywebplans.com
                    </span>
                    <span className="flex items-center gap-[8px] mt-[0.7rem]">
                      {" "}
                      <BiSolidPhoneCall />{" "}
                      <a href="tel:+919216041313">+91 92160-41313</a>,{" "}
                      <a href="tel:+8591383962">+91 85913-83962</a>
                    </span>
                  </h5>
                </Framer>
              )}
              {activeTab === 1 && (
                <Framer>
                  <h4 className="text-[22px] font-SF-Pro-Semibold text-[#131d2b] leading-[1.36]">
                    India - Chandigarh
                  </h4>
                  <h5 className="text-[16px] text-[#4c525a] leading-[1.56] mt-[0.3rem]">
                    <span className="flex items-center gap-[8px]">
                      SCO 60-62, 2nd Floor, Sector-17C, Chandigarh, India.
                    </span>
                    {/* <br /> */}
                    <span> Pin code: 160017.</span>
                    <br />
                    <span className="flex items-center gap-[8px] mt-[0.7rem]">
                      <MdEmail /> Info@easywebplans.com
                    </span>
                    <span className="flex items-center gap-[8px] mt-[0.7rem]">
                      {" "}
                      <BiSolidPhoneCall />{" "}
                      <a href="tel:+919216041313">+91 92160-41313</a>,{" "}
                      <a href="tel:+8591383962">+91 85913-83962</a>
                    </span>
                  </h5>
                </Framer>
              )}
              {activeTab === 2 && (
                <Framer>
                  <h4 className="text-[22px] font-SF-Pro-Semibold text-[#131d2b] leading-[1.36]">
                    India - Patiala
                  </h4>
                  <h5 className="text-[16px] text-[#4c525a] leading-[1.56] mt-[0.3rem]">
                    <span className="flex items-center gap-[8px]">
                      #44 B.N Khalsa School Complex Sirhind Road Patiala,
                      Punjab, India.
                    </span>
                    {/* <br /> */}
                    <span> Pin code: 147001.</span>
                    <br />
                    <span className="flex items-center gap-[8px] mt-[0.7rem]">
                      <MdEmail /> Info@easywebplans.com
                    </span>
                    <span className="flex items-center gap-[8px] mt-[0.7rem]">
                      {" "}
                      <BiSolidPhoneCall />{" "}
                      <a href="tel:+919216041313">+91 92160-41313</a>,{" "}
                      <a href="tel:+8591383962">+91 85913-83962</a>
                    </span>
                  </h5>
                </Framer>
              )}
            </div>
          </div>
          <li
            className={` navbar-content-3 items-center ${
              menuOpen ? "flex gap-x-2" : "hidden"
            } xl:flex xl:gap-x-3`}
          >
            <Button4 />
            <Button3 text={"Contact Us"} />
          </li>
          <div className="xl:hidden  hamburger">
            <Hamburger
              size={25}
              color="#323B4B"
              toggled={menuOpen}
              toggle={setMenuOpen}
            />
          </div>
        </ul>
      </div>
      <ModalFormat isOpen={isOpen} onClose={closeModal}>
        <ContactCard />
      </ModalFormat>
    </nav>
  );
}

export default Navbar;
