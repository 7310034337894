// src/Modal.js
import React, { useEffect } from "react";
import "./modal.css";
import {RxCross1} from "react-icons/rx"

function ModalFormat({ isOpen, onClose, children }) {
  useEffect(() => {
    const hasModalBeenDisplayed = localStorage.getItem("modalDisplayed");
    if (!hasModalBeenDisplayed) {
      localStorage.setItem("modalDisplayed", "true");
      onClose(); // Automatically close the modal after the first display
    }
  }, [onClose]);

  if (!isOpen) return null;
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="modal-overlay backdrop-blur-sm" onClick={onClose}></div>
      <div className="modal-container relative w-fit flex flex-col justify-center items-center rounded-lg p-4 ">
        <div onClick={onClose} className="absolute cursor-pointer lg:right-0 right-[1.5rem] top-[4.5rem] lg:top-[1.5rem]"><RxCross1 className="text-black text-xl relative z-20"/></div>
        {children}
      </div>
    </div>
  );
}

export default ModalFormat;
