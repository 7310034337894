import React, { useEffect, useState } from "react";
import { Tilt } from "react-tilt";
import { ContactCard } from "./contact-card";
import "./contactForm.css";
import googleMapReact from "google-map-react";
import GoogleMapComponent from "../../../map/googleMap";

export function ContactForm1() {
  return (
    <div id="contact" style={{backgroundImage:"linear-gradient(to bottom,#fff,#ebeef3),linear-gradient(to bottom,#005cff,#e0e9f9)"}} className="relative pb-[7rem] bg-cover bg-center">
      <img className="hidden lg:block absolute  lg:bottom-[-40px] w-full" src="https://www.code-brew.com/wp-content/uploads/2023/01/footer-shape-1-1.webp"/>
      <div className=" absolute top-0 h-[33rem] md:h-[40rem] xl:h-[40rem] w-full bg-black"></div>
      <div className="container">
        <div className="lg:grid lg:grid-cols-6">
          <div className="col-span-3">
            <div className="h-[400px] main-div">
              <div className="z-20 mt-[5rem] md:mt-[14rem] xl:mt-[12rem] flex flex-col crausal-text">
                <div className="w-full px-[30px] lg:px-0 lg:w-[80%]">
                  <h1 className="font-GalanoSemiBold text-white text-[30px] sm:text-[40px] lg:text-[40px]">
                    Top <span className="text-[#6bd1a6]">App Developers</span>{" "}
                    in Easy-Web-Plan
                  </h1>
                  <p
                    style={{ color: "white", letterSpacing: "1px" }}
                    className="text-[16px] sm:text-[20px] lg:text-base text-justify font-GalanoLight "
                  >
                    Easy-Web-Plan has fanatical high standards of
                    development trusted by Government, Small/Medium sized
                    businesses and Start-ups.
                  </p>
                  <div>
                    <div className="font-GalanoRegular text-white text-[16px] sm:text-[18px] mb-[1.5rem]">
                      {" "}
                      Recognised in:
                    </div>
                    <div className="grid grid-cols-4 items-center  gap-x-4">
                      <div
                        style={{ borderRight: "1px solid #fff" }}
                        className="flex justify-center"
                      >
                        <Tilt>
                          <img
                            className="max-h-[50px]"
                            src="/assets/contact-brand-1.webp"
                          />
                        </Tilt>
                      </div>
                      <div className="flex justify-center">
                        <Tilt>
                          <img
                            className="max-h-[50px] "
                            src="/assets/contact-brand-2.png"
                          />
                        </Tilt>
                      </div>
                      <div
                        style={{
                          borderRight: "1px solid #fff",
                          borderLeft: "1px solid #fff",
                        }}
                        className="flex justify-center"
                      >
                        <Tilt>
                          <img
                            className="max-h-[50px]"
                            src="/assets/contact-brand-3.png"
                          />
                        </Tilt>
                      </div>
                      <div className="flex justify-center">
                        <Tilt>
                          <img
                            className="max-h-[50px]"
                            src="/assets/contact-brand-4.webp"
                          />
                        </Tilt>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[300px] md:h-[400px] hidden lg:block main-div "></div>
            <div className="h-[330px] lg:h-[400px] main-div mt-[-10rem]">
              <div className="container">
                <div className="flex flex-col md:flex-row items-center gap-x-[2rem] gap-y-[2rem]">
                  <div className="new-heading-style-1 w-full">
                    <div>
                      <h5 className="flex items-center gap-x-3">
                        <img className="w-[2rem]" src="/assets/handshake.png" />
                        Partnerships
                      </h5>
                      <h4>
                        Empower your clients with Industry-Leading Technology
                        Solution
                      </h4>
                      <p>
                        Collaborate with us to earn more by selling, customizing
                        or integrating our products
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[400px] main-div ">
              <div className="container">
                <GoogleMapComponent />
              </div>
            </div>
          </div>
          <div className="fixed-div flex justify-center col-span-3 h-fit lg:h-[38rem]">
            <ContactCard />
          </div>
        </div>
      </div>
    </div>
  );
}
