import React, {useState} from "react";
import "./button4.css";
import ModalFormat from "../../modal/modalFormat";
import { ContactCard } from "../../sections/home-section/contactForm1/contact-card";

export function Button4() {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <button className="button4 whitespace-nowrap backdrop-blur-xl bg-[#ffffff]/5 ">
        <div onClick={()=>setIsOpen(true)}>Request A Demo</div>
        <span class="first"></span>
        <span class="second"></span>
        <span class="third"></span>
        <span class="fourth"></span>
      </button>
      <ModalFormat isOpen={isOpen} onClose={closeModal}>
        <ContactCard />
      </ModalFormat>
    </div>
  );
}
