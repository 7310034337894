import React, { useState, useEffect } from "react";

export function NavItem({ label, menu }) {
  const [isHovered, setIsHovered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  useEffect(() => {
    // Function to update the window width
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      className="font-SF-Pro-Semibold font-semibold relative inline-block my-0 mx-[5px]"
      onClick={() => setIsHovered(!isHovered)}
      onMouseEnter={windowWidth >= 992 ? handleMouseEnter : null}
      onMouseLeave={windowWidth >= 992 ? handleMouseLeave : null}
    >
      <div
        style={{ display: "flex", flexDirection: "row" }}
        className="relative menuItem font-SF-Pro-Semibold whitespace-nowrap justify-between flex items-center "
      >
        {label}
        
      </div>

  
    </div>
  );
}
