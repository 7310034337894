// AppRouter.js
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/navbar";
import ScrollToTop from "react-scroll-to-top";
import AppDevelopment from "./components/pages/AppDevelopment";
import { CBFooter } from "./components/Footer/cbFooter";
import { Tooltip } from "@material-tailwind/react";

const AppRouter = () => {
  return (
    <div>
      <div style={{ zIndex: 2 }} className="relative">
        <div
          style={{ zIndex: "3" }}
          className="bg-white main-content lg:mb-[600px] overflow-visible"
        >
          <div className="relative">
            <Navbar />
            {/* <CustomScrollBar /> */}
          </div>
          <Router>
            <Switch>
              <Route exact path="/" component={AppDevelopment} />
              {/* <Route exact path="/web-development" component={WebDevelopment} />
              <Route exact path="/app-development" component={AppDevelopment} />
              <Route exact path="/blockchain" component={Blockchain} />
              <Route exact path="/digital-marketing" component={Marketing} />
              <Route
                exact
                path="/software-development"
                component={SoftwareDevelopment}
              />
              <Route exact path="/ui-ux" component={UiUx} />
              <Route exact path="/premium-custom" component={PremiumCustom} />
              <Route exact path="/about" component={AboutUs} />
              <Route exact path="/contactUs" component={ContactUs} /> */}
            </Switch>
          </Router>
        </div>
      </div>
      {/* <Footer/> */}
      <a href="tel:+919216041313" className="wp-call-button"></a>
      <Tooltip
        content="Chat on WhatsApp"
        className="bg-[#25D366]"
        placement="right"
        animate={{
          mount: { scale: 1, x: 0 },
          unmount: { scale: 0, x: -100 },
        }}
      >
        <a
          href="https://wa.me/919216041313/?text=Hello"
          className="wp-msg-button"
        >
          <img src="/assets/whatsapp-logo.png" />
        </a>
      </Tooltip>

      <CBFooter />
      <ScrollToTop smooth style={{ zIndex: 10 }} />
    </div>
  );
};

export default AppRouter;
